var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "RevenueTable"
  }, [_c('BaseDashboardTable', {
    staticClass: "revenue-dashboard-table",
    attrs: {
      "columns": _vm.tableColumns,
      "items": _vm.tableItems,
      "sort": _vm.sortState,
      "isClickable": true,
      "emptyMessage": "Keine Daten vorhanden"
    },
    on: {
      "sort-changed": _vm.handleSortChange,
      "row-click": _vm.handleRowClick
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('h3', [_vm._v(_vm._s(_vm.title))])];
      },
      proxy: true
    }, {
      key: "column-name",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('div', {
          staticClass: "name-column"
        }, [_vm._v(_vm._s(item.name))])];
      }
    }, {
      key: "column-income",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(item.incomeFormatted) + " ")];
      }
    }, {
      key: "column-changePercentage",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('div', {
          domProps: {
            "innerHTML": _vm._s(item.changeColumnHTML)
          }
        })];
      }
    }, {
      key: "column-timePeriod",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_vm._v(" " + _vm._s(item.timePeriodFormatted) + " ")];
      }
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }