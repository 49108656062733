var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "Revenue",
    class: {
      'is-loading': _vm.isFetchingData
    }
  }, [_c('Toolbar', [_c('div', [_c('Dropdown', {
    attrs: {
      "hasSearch": "",
      "items": _vm.drivers.filter(function (d) {
        return d.isVisible;
      }),
      "selected": _vm.selectedEmployee,
      "placeholder": "Fahrer"
    },
    on: {
      "onItemSelect": _vm.handleEmployeeSelect
    }
  }), _c('Dropdown', {
    attrs: {
      "hasSearch": "",
      "items": _vm.cars,
      "selected": _vm.selectedCar,
      "placeholder": "Kennzeichen"
    },
    on: {
      "onItemSelect": _vm.handleCarSelect
    }
  }), _c('Datepicker', {
    attrs: {
      "monthPicker": "",
      "startDate": _vm.selectedFrom
    },
    on: {
      "onChange": _vm.handleDateChange
    }
  }), _c('Button', {
    attrs: {
      "size": "small",
      "isLoading": _vm.isFetchingData
    },
    on: {
      "onClick": _vm.handleQuery
    }
  }, [_vm._v(" Anzeigen ")]), _c('PageNavigationToolbar', {
    attrs: {
      "selectedCar": _vm.selectedCar,
      "selectedEmployee": _vm.selectedEmployee
    }
  })], 1), _c('div', [_c('Download', {
    attrs: {
      "title": "Download PDF",
      "type": "pdf"
    },
    on: {
      "onDownload": _vm.handleDownload
    }
  })], 1)]), _vm.aggregatedMonthlyData.length ? _c('ChartV2', {
    attrs: {
      "halfHeight": "",
      "color": "green",
      "type": "bar",
      "title": " ",
      "values": _vm.aggregatedMonthlyData
    },
    on: {
      "onLeave": function onLeave($event) {
        _vm.activeRow = null;
      }
    }
  }, [_c('RevenueSummary', {
    attrs: {
      "currentRevenue": _vm.shiftsIncome,
      "comparisonRevenue": _vm.lastMonthIncome,
      "currentStartDate": _vm.currentSelectedFrom,
      "currentEndDate": _vm.currentSelectedTo,
      "comparisonStartDate": _vm.formatDateToPreviousMonthStart,
      "comparisonEndDate": _vm.formatDateToPreviousMonthEnd
    }
  })], 1) : _vm._e(), _vm.chartData.length && !_vm.aggregatedMonthlyData.length ? _c('ChartV2', {
    attrs: {
      "halfHeight": "",
      "type": "bar",
      "title": " ",
      "values": _vm.chartData,
      "activeBar": _vm.activeBar
    },
    on: {
      "onLeave": function onLeave($event) {
        _vm.activeRow = null;
      }
    }
  }, [_c('RevenueSummary', {
    attrs: {
      "currentRevenue": _vm.shiftsIncome,
      "comparisonRevenue": _vm.lastMonthIncome,
      "currentStartDate": _vm.currentSelectedFrom,
      "currentEndDate": _vm.currentSelectedTo,
      "comparisonStartDate": _vm.formatDateToPreviousMonthStart,
      "comparisonEndDate": _vm.formatDateToPreviousMonthEnd
    }
  })], 1) : _vm._e(), _vm.revenueByEmployee ? _c('div', {
    staticClass: "Revenue-Tables"
  }, [_c('revenue-table', {
    attrs: {
      "shifts": _vm.revenueByEmployee,
      "title": "Umsatz nach Fahrer"
    },
    on: {
      "onRowClick": function onRowClick(props) {
        return _vm.handleRowClick(Object.assign({}, props, {
          type: 'employee'
        }));
      }
    }
  }), _c('revenue-table', {
    attrs: {
      "shifts": _vm.revenueByCar,
      "title": "Umsatz nach Fahrzeug"
    },
    on: {
      "onRowClick": function onRowClick(props) {
        return _vm.handleRowClick(Object.assign({}, props, {
          type: 'car'
        }));
      }
    }
  })], 1) : _c('EmptyState')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }