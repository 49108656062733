<template>
    <div class="RevenueTable">
        <BaseDashboardTable
            class="revenue-dashboard-table"
            :columns="tableColumns"
            :items="tableItems"
            :sort="sortState"
            :isClickable="true"
            emptyMessage="Keine Daten vorhanden"
            @sort-changed="handleSortChange"
            @row-click="handleRowClick"
        >
            <template #title>
                <h3>{{ title }}</h3>
            </template>

            <!-- Custom column renderers -->
            <template #column-name="{ item }">
                <div class="name-column">{{ item.name }}</div>
            </template>

            <template #column-income="{ item }">
                {{ item.incomeFormatted }}
            </template>

            <template #column-changePercentage="{ item }">
                <div v-html="item.changeColumnHTML"></div>
            </template>

            <template #column-timePeriod="{ item }">
                {{ item.timePeriodFormatted }}
            </template>
        </BaseDashboardTable>
    </div>
</template>

<script>
import BaseDashboardTable from '@/components/BaseDashboardTable.vue';
import { format } from 'date-fns';
import { euro } from '@/lib/helper';

export default {
    name: 'RevenueTable',
    components: {
        BaseDashboardTable,
    },
    props: {
        title: {
            type: String,
        },
        shifts: {
            type: Object,
        },
    },
    data: () => {
        return {
            sortState: {
                column: 'income',
                ascending: false,
            },
        };
    },
    computed: {
        tableColumns() {
            return [
                {
                    key: 'name',
                    label: 'Name',
                    sortable: true,
                    priority: 'high',
                },
                {
                    key: 'income',
                    label: 'Umsatz (€)',
                    sortable: true,
                },
                {
                    key: 'changePercentage',
                    label: 'Vergleich Vormonat',
                    sortable: true,
                    priority: 'high',
                },
                {
                    key: 'timePeriod',
                    label: 'Zeitraum',
                    sortable: true,
                    priority: 'medium',
                },
            ];
        },
        tableItems() {
            return this.formatShifts(this.shifts);
        },
    },
    methods: {
        handleSortChange(sortData) {
            // Map the column key to the old sort column names if needed
            const columnMapping = {
                name: 'Name',
                income: 'Umsatz (€)',
                changePercentage: 'Vergleich Vormonat',
                timePeriod: 'Zeitraum',
            };

            this.sortState = {
                column: sortData.column,
                ascending: sortData.ascending,
            };
        },
        handleRowClick({ item }) {
            this.$emit('onRowClick', item.original);
        },
        formatShifts(shifts) {
            const result = [];
            for (const shift of Object.values(shifts)) {
                const percentageChange =
                    shift.previousIncome > 0
                        ? ((shift.income - shift.previousIncome) / shift.previousIncome) * 100
                        : null;

                // Create a formatted item with properties the BaseDashboardTable can use
                result.push({
                    id: shift.id,
                    name: shift?.name || '',
                    income: shift?.income || 0,
                    incomeFormatted: `${euro(shift.income).format()}€`,
                    previousIncome: shift.previousIncome,
                    changePercentage: percentageChange,
                    changeColumnHTML: this.createChangeColumn(percentageChange),
                    timePeriod: shift.timePeriod,
                    timePeriodFormatted: shift.timePeriod
                        ? format(new Date(shift.timePeriod.start), 'dd.MM') +
                          ' - ' +
                          format(new Date(shift.timePeriod.end), 'dd.MM')
                        : '',
                    // Add raw date objects for proper sorting
                    _timePeriodStart: shift.timePeriod ? new Date(shift.timePeriod.start) : null,
                    _timePeriodEnd: shift.timePeriod ? new Date(shift.timePeriod.end) : null,
                    original: shift, // Keep the original shift object for reference
                    tooltips: {
                        changePercentage: {
                            autoHide: true,
                            content: `Vormonat: ${euro(shift.previousIncome).format()}€`,
                        },
                    },
                });
            }

            // Sort the results according to the current sort state
            return this.sortItems(result);
        },
        sortItems(items) {
            const { column, ascending } = this.sortState;

            return items.sort((a, b) => {
                let aVal, bVal;

                // Handle specific column types for proper sorting
                if (column === 'timePeriod') {
                    // Sort by start date for time periods
                    aVal = a._timePeriodStart;
                    bVal = b._timePeriodStart;
                } else if (column === 'income') {
                    // Use the numeric income value directly
                    aVal = a.income;
                    bVal = b.income;
                } else if (column === 'changePercentage') {
                    // Use the raw percentage value
                    aVal = a.changePercentage;
                    bVal = b.changePercentage;
                } else {
                    // For other columns (like 'name'), use the direct property
                    aVal = a[column];
                    bVal = b[column];

                    // Case insensitive comparison for strings
                    if (typeof aVal === 'string' && typeof bVal === 'string') {
                        aVal = aVal.toLowerCase();
                        bVal = bVal.toLowerCase();
                    }
                }

                // Handle null, undefined or special values
                if (aVal === null && bVal === null) return 0;
                if (aVal === null) return ascending ? 1 : -1;
                if (bVal === null) return ascending ? -1 : 1;

                if (aVal === undefined && bVal === undefined) return 0;
                if (aVal === undefined) return ascending ? 1 : -1;
                if (bVal === undefined) return ascending ? -1 : 1;

                // Standard comparison
                if (aVal < bVal) return ascending ? -1 : 1;
                if (aVal > bVal) return ascending ? 1 : -1;

                return 0; // When both values are equal
            });
        },
        createChangeColumn(percentageChange) {
            if (percentageChange === null) {
                return 'N/A'; // No comparison available
            }

            const isPositive = percentageChange > 0;
            return `
        <div style="display: flex; align-items: center;">
            <i
                class="${isPositive ? 'ri-arrow-up-line' : 'ri-arrow-down-line'}"
                style="color: ${
                    isPositive ? 'var(--color-green-dark)' : 'var(--color-red)'
                }; margin-right: 5px;">
            </i>
            <span style="color: ${
                isPositive ? 'var(--color-green-dark)' : 'var(--color-red)'
            };">${Math.abs(percentageChange).toFixed(0)}%</span>
            <i
                class="ri-information-line"
                style="margin-left: 6px; color: var(--color-text-gray); cursor: pointer;"
            ></i>
        </div>
    `;
        },
    },
};
</script>

<style lang="scss">
.RevenueTable {
    .BaseDashboardTable {
        height: 100%;
    }

    .revenue-dashboard-table {
        .table-content {
            .align-right {
                font-feature-settings: 'tnum';
                font-variant-numeric: tabular-nums;
            }
        }

        .name-column {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 100%;
            display: block;
        }
    }

    .ri-arrow-up-line {
        color: var(--color-green-dark);
        font-size: 1rem;
    }

    .ri-arrow-down-line {
        color: var(--color-red);
        font-size: 1rem;
    }

    @media screen and (max-width: 992px) {
        .revenue-dashboard-table {
            thead th,
            tbody td {
                padding: 4px 6px;
            }
        }
    }
}
</style>
